import { Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TFunction } from 'i18next';
import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTimePicker } from '@/contexts/timepicker';

type RelativeTimes = 'minutes' | 'hours' | 'days' | 'weeks' | 'months';
const useStyles = makeStyles()((theme: Theme) => ({
  relativeTimes: {
    border: '1px solid #ddd',
    padding: '10px',
  },
  btnClass: {
    margin: '5px',
  },
  spanWidth: {
    width: '70px',
    display: 'inline-block',
  },
}));

const minutes: number[] = [1, 3, 5, 15, 30, 45].map((numMinutes) => numMinutes.minutes);
const hours: number[] = [1, 2, 3, 6, 8, 12].map((numHours) => numHours.hours);
const days: number[] = [1, 2, 3, 4, 5, 6].map((numDays) => numDays.days);
const weeks: number[] = [1, 2, 4, 6].map((numWeeks) => (numWeeks * 7).days);
const months: number[] = [3].map((numMonths) => (numMonths * 30).days);
interface Properties {
  updateTime: (startDate: Date, endDate: Date) => void;
  t: TFunction;
}

const RelativeTimes: FunctionComponent<Properties> = (props) => {
  const { classes } = useStyles();
  const { t } = props;

  const { from, to } = useTimePicker();

  const [selectionValue, setSelectionValue] = useState<number | null>(() => {
    const initialDuration = (to?.getTime() || Date.now()) - from.getTime();
    return (
      [...months, ...weeks, ...days, ...hours, ...minutes].find((duration) => duration === initialDuration) ?? null
    );
  });

  const onChangeRelativeTime = (duration: number) => (): void => {
    setSelectionValue(duration);
    const now = Date.now();
    props.updateTime(new Date(now - duration), new Date(now));
  };

  const renderTimings = (durations: number[], miliToDuration: number) => {
    return durations.map((duration: number) => {
      return (
        <Button
          className={classes.btnClass}
          color={selectionValue === duration ? 'primary' : 'inherit'}
          variant="contained"
          key={duration}
          onClick={onChangeRelativeTime(duration)}
        >
          {duration / miliToDuration}
        </Button>
      );
    });
  };

  return (
    <div>
      <div className={classes.relativeTimes}>
        {' '}
        <Typography className={classes.spanWidth}>
          {t(['shared:minutes'], { defaultValue: 'Minutes' })} {': '}
        </Typography>{' '}
        {renderTimings(minutes, (1).minutes)}{' '}
      </div>

      <div className={classes.relativeTimes}>
        {' '}
        <Typography className={classes.spanWidth}>
          {t(['shared:hours'], { defaultValue: 'Hours' })} {': '}
        </Typography>{' '}
        {renderTimings(hours, (1).hours)}{' '}
      </div>

      <div className={classes.relativeTimes}>
        {' '}
        <Typography className={classes.spanWidth}>
          {t(['shared:days'], { defaultValue: 'Days' })} {': '}
        </Typography>{' '}
        {renderTimings(days, (1).days)}{' '}
      </div>

      <div className={classes.relativeTimes}>
        {' '}
        <Typography className={classes.spanWidth}>
          {t(['shared:weeks'], { defaultValue: 'Weeks' })} {': '}
        </Typography>{' '}
        {renderTimings(weeks, (7).days)}{' '}
      </div>

      <div className={classes.relativeTimes}>
        {' '}
        <Typography className={classes.spanWidth}>
          {t(['shared:months'], { defaultValue: 'Months' })} {': '}
        </Typography>{' '}
        {renderTimings(months, (30).days)}{' '}
      </div>
    </div>
  );
};

export default RelativeTimes;
