import { Radio, RadioGroup, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Theme, useTheme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import { TFunction } from 'i18next';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { WithUtils } from '@/hocs';

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  boxHeight: {
    height: '400px',
    overflowY: 'scroll',
  },
}));

interface Properties {
  batchItems: Pick<Schema.Batch, 'batchId' | 'batchNumber' | 'actualStart' | 'actualStop'>[];
  batchSelected: (batch: Pick<Schema.Batch, 'batchId' | 'batchNumber' | 'actualStart' | 'actualStop'>) => void;
  utils: WithUtils['utils'];
  t: TFunction;
}

export default function RecentBatchesSelector(props: Properties) {
  const { classes } = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    const selectedTime = props.batchItems.find((item) => item.batchId === event.target.value);
    if (!selectedTime) {
      return;
    }

    props.batchSelected(selectedTime);
  };

  const { utils, t } = props;

  const getFromAndTo = (start: Date, end: Date) => {
    return t(['shared:dateRangeTo'], {
      defaultValue: '{{fromDate}} to {{toDate}}',
      fromDate: utils.formatByString(start, 'dd/MM - yyyy, HH:mm'),
      toDate: utils.formatByString(end, 'dd/MM - yyyy, HH:mm'),
    });
  };

  return (
    <div className={classes.boxHeight}>
      <FormControl variant="filled" component="fieldset">
        <RadioGroup aria-label="recent-batches" name="recent-batches" value={value} onChange={handleChange}>
          {props.batchItems.map((item) => {
            if (item.actualStart) {
              const start = new Date(item.actualStart);
              const end = item.actualStop ? new Date(item.actualStop) : new Date();
              return (
                <FormControlLabel
                  key={item.batchId}
                  value={item.batchId}
                  // eslint-disable-next-line react/forbid-component-props
                  control={<Radio color="primary" style={{ marginBottom: theme.spacing(1.5) }} />}
                  label={
                    <>
                      <Typography variant="body2">{item.batchNumber}</Typography>
                      <Typography color="textSecondary" variant="caption">
                        {getFromAndTo(start, end)}
                      </Typography>
                    </>
                  }
                  labelPlacement="end"
                  title={getFromAndTo(start, end)}
                />
              );
            }
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
