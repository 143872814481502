import React from 'react';

type HTMLDivProps = React.HTMLAttributes<HTMLDivElement>;

interface Properties<T = any> {
  children: React.ReactNode;
  dayInCurrentMonth: boolean;
  disabled: boolean;
  onSelect: (value: T) => void;
  onMouseEnter: HTMLDivProps['onMouseEnter'];
  onMouseLeave: HTMLDivProps['onMouseLeave'];
  value: T;
}

class DayWrapper<T> extends React.PureComponent<Properties<T>, {}> {
  static defaultProps = {
    dayInCurrentMonth: true,
    disabled: false,
  };

  handleClick: React.HTMLAttributes<HTMLDivElement>['onClick'] & React.HTMLAttributes<HTMLDivElement>['onKeyPress'] =
    () => {
      this.props.onSelect(this.props.value);
    };

  render() {
    const {
      children,
      value,
      dayInCurrentMonth,
      disabled,
      onSelect,

      ...other
    } = this.props;
    return (
      <div
        onClick={dayInCurrentMonth && !disabled ? this.handleClick : undefined}
        onKeyPress={dayInCurrentMonth && !disabled ? this.handleClick : undefined}
        role="presentation"
        {...other}
      >
        {children}
      </div>
    );
  }
}

export default DayWrapper;
