import { TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { CalendarHeader, Day, DayWrapper } from '@/components/pickers/date-time-range-picker';
import { WithUtils, withUtils } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  calendar: {
    height: 36 * 6,
    marginTop: theme.spacing(1.5),
  },
  week: {
    display: 'flex',
    justifyContent: 'center',
  },
  calendarWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  hour: {
    maxWidth: '7em',
  },
  minute: {
    maxWidth: '7em',
  },
  timeContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

interface Properties {
  disableHours?: boolean;
  date?: Date;
  minDate?: string | number | Date;
  maxDate?: string | number | Date;
  onChange?: (from: Date, to?: Date) => void;
  disablePast: boolean;
  disableFuture: boolean;
  leftArrowIcon: React.ReactNode;
  rightArrowIcon: React.ReactNode;
  shouldDisableDate?: (date: Date) => boolean;
}

interface State {
  currentMonth: Date;
  hoveredDay: Date | null;
}

/* eslint-disable no-unused-expressions */
class SingleCalendar extends React.Component<
  Properties & WithTranslation & WithUtils & WithStyles<typeof styles>,
  State
> {
  static defaultProps: Properties = {
    date: undefined,
    minDate: '1900-01-01',
    maxDate: '2100-01-01',
    onChange: undefined,
    disablePast: false,
    disableFuture: false,
    leftArrowIcon: undefined,
    rightArrowIcon: undefined,
    shouldDisableDate: () => false,
  };

  readonly state: State = {
    currentMonth: this.props.utils.startOfMonth(this.props.date ? this.props.date : new Date()),
    hoveredDay: null,
  };

  onDateSelect = (day: Date) => {
    const { date, utils, onChange } = this.props;

    if (!date || !onChange) {
      return;
    }

    const withHours = utils.setHours(day, utils.getHours(date));
    const withMinutes = utils.setMinutes(withHours, utils.getMinutes(date));

    onChange(withMinutes, withMinutes);
  };

  onDayHover = (enter: boolean, day: Date) => {
    if (enter) {
      this.setState({ hoveredDay: day });
    } else {
      this.setState({ hoveredDay: null });
    }
  };

  handleNextMonth = () => {
    this.setState({
      currentMonth: this.props.utils.getNextMonth(this.state.currentMonth),
    });
  };

  handlePreviousMonth = () => {
    this.setState({
      currentMonth: this.props.utils.getPreviousMonth(this.state.currentMonth),
    });
  };

  validateMinMaxDate = (day: Date) => {
    const { minDate, maxDate, utils } = this.props;

    const parsedMinDate = minDate instanceof Date ? minDate : new Date(minDate ?? '');
    const parsedMaxDate = maxDate instanceof Date ? maxDate : new Date(maxDate ?? '');

    return (
      (parsedMinDate && utils.isBeforeDay(day, parsedMinDate)) ||
      (parsedMaxDate && utils.isAfterDay(day, parsedMaxDate))
    );
  };

  shouldDisablePrevMonth = () => {
    const { utils, disablePast, minDate } = this.props;
    const now = new Date();

    return !utils.isBefore(
      utils.startOfMonth(disablePast && utils.isAfter(now, new Date(minDate ?? '')) ? now : new Date(minDate ?? '')),
      this.state.currentMonth,
    );
  };

  shouldDisableNextMonth = () => {
    const { utils, disableFuture, maxDate } = this.props;
    const now = new Date();
    return !utils.isAfter(
      utils.startOfMonth(disableFuture && utils.isBefore(now, new Date(maxDate ?? '')) ? now : new Date(maxDate ?? '')),
      this.state.currentMonth,
    );
  };

  shouldDisableDate = (day: Date) => {
    const { disablePast, disableFuture, shouldDisableDate, utils } = this.props;

    const now = new Date();

    return (
      (disableFuture && utils.isAfterDay(day, now)) ||
      (disablePast && utils.isBeforeDay(day, now)) ||
      this.validateMinMaxDate(day) ||
      shouldDisableDate?.(day)
    );
  };

  moveToDay = (day: Date) => {
    const { onChange } = this.props;
    if (onChange && day && !this.shouldDisableDate(day)) {
      onChange(day);
    }
  };

  renderWeeks = (prev: boolean) => {
    const { utils } = this.props;

    let { currentMonth } = this.state;
    if (prev) {
      currentMonth = utils.getPreviousMonth(currentMonth);
    }
    const weeks = utils.getWeekArray(currentMonth);

    return weeks.map((week) => (
      <div key={`week-${week[0].toString()}`} className={this.props.classes.week}>
        {this.renderDays(week, currentMonth)}
      </div>
    ));
  };

  renderDays = (week: Date[], currentMonth: Date) => {
    let { date } = this.props;
    const { utils } = this.props;

    const currentMonthNumber = utils.getMonth(currentMonth);
    const now = new Date();

    if (date) {
      date = utils.startOfDay(date);
    }

    return week.map((day) => {
      const disabled = this.shouldDisableDate(day);
      const dayInCurrentMonth = utils.getMonth(day) === currentMonthNumber;
      const selected = date ? utils.isSameDay(date, day) : false;

      return (
        <DayWrapper
          key={day.toString()}
          value={day}
          dayInCurrentMonth={dayInCurrentMonth}
          disabled={disabled}
          onSelect={this.onDateSelect}
          onMouseEnter={this.handleOnMouse(disabled, dayInCurrentMonth, day, true)}
          onMouseLeave={this.handleOnMouse(disabled, dayInCurrentMonth, day, false)}
        >
          <Day
            current={utils.isSameDay(day, now)}
            hidden={!dayInCurrentMonth}
            disabled={disabled}
            selected={selected}
            betweenSelected={false}
          >
            {utils.formatByString(day, 'd')}
          </Day>
        </DayWrapper>
      );
    });
  };

  render() {
    const { currentMonth } = this.state;
    const { classes, utils, date = new Date(), disableHours, t } = this.props;

    return (
      <div className={classes.calendarWrapper}>
        <div>
          <CalendarHeader
            currentMonth={currentMonth}
            onNextMonth={this.handleNextMonth}
            onPreviousMonth={this.handlePreviousMonth}
            leftArrowIcon={this.props.leftArrowIcon}
            rightArrowIcon={this.props.rightArrowIcon}
            disablePrevMonth={this.shouldDisablePrevMonth()}
            disableNextMonth={this.shouldDisableNextMonth()}
          />

          <div className={classes.calendar}>{this.renderWeeks(false)}</div>

          {!disableHours && (
            <div className={classes.timeContainer}>
              <TextField
                variant="filled"
                id="hour"
                label={t(['shared:hour'], { defaultValue: 'hour' }).titleCase}
                value={utils.getHours(date)}
                type="number"
                className={classes.hour}
                inputProps={{ style: { textAlign: 'center' } }}
                onChange={this.onChangeTime('hour')}
              />
              <TextField
                variant="filled"
                id="min"
                label={t(['shared:abbrMinutes'], { defaultValue: 'min' }).titleCase}
                value={utils.getMinutes(date)}
                type="number"
                className={classes.minute}
                inputProps={{ style: { textAlign: 'center' } }}
                onChange={this.onChangeTime('min')}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
  private handleOnMouse = (disabled: unknown, dayInCurrentMonth: boolean, day: Date, enter: boolean) => () => {
    if (!disabled && dayInCurrentMonth) {
      this.onDayHover(enter, day);
    }
  };

  private onChangeTime =
    (timeTarget: 'hour' | 'min') =>
    (e: {
      target: {
        value: string;
      };
    }) => {
      const { onChange, utils } = this.props;
      if (!onChange) {
        return;
      }

      let { date = new Date() } = this.props;

      let val = Number(e.target.value);
      if (timeTarget === 'hour') {
        val = ((val % 24) + 24) % 24;
        date = utils.setHours(date, val);
      } else {
        val = ((val % 60) + 60) % 60;
        date = utils.setMinutes(date, val);
      }

      onChange(date);
    };
}
const enhance = compose<unknown, Properties>(
  withUtils,
  withTranslation(['shared']),
  withStyles(styles, { name: 'MUITPickersCalendar' }),
);
export default enhance(SingleCalendar as React.ComponentType<unknown>);
