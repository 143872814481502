import * as Apollo from '@apollo/client/react/components';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Modal,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import WideSnackbar from '@/components/bars/wide-snackbar';
import LoadingOrError from '@/components/loading/loading-or-error';
import { DoubleCalendar, SingleCalendar } from '@/components/pickers/date-time-range-picker';
import RecentBatchesSelector from '@/components/pickers/date-time-range-picker/recent-batches-selector';
import RecentShifts from '@/components/pickers/date-time-range-picker/recent-shifts';
import RelativeTimes from '@/components/pickers/date-time-range-picker/relative-times';
import { recentBatchesFromLine } from '@/graphql/queries';
import { isBrowserLocale24h } from '@/helpers/helper-functions';
import { WithLine, WithUtils, WithWidth, withLine, withUtils, withWidth } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  card: {
    display: 'block',
  },
  content: {
    overflowX: 'auto',
  },
  actionContainer: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    flexWrap: 'wrap',
  },
  actionTypography: {
    display: 'flex',
    marginBottom: 4,
    marginTop: 4,
  },
  actionButtonGroup: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'end',
  },
  actionButton: {
    minWidth: '80px',
  },
  loading: { width: '100%', height: '100%', display: 'flex' },
  modalContainer: {
    overflow: 'scroll',
    maxHeight: 'calc(calc(90vh - env(safe-area-inset-bottom)) - env(safe-area-inset-top))',
    maxWidth: 'calc(calc(100vw - env(safe-area-inset-left)) - env(safe-area-inset-right))',
    border: 'none',
    margin: 'auto',
  },
  doubleCalendarError: { paddingLeft: theme.spacing(2), margin: theme.spacing(), marginTop: theme.spacing(2) },
});

interface Properties {
  disablePast?: boolean;
  disableFuture?: boolean;
  leftArrowIcon?: React.ReactNode;
  rightArrowIcon?: React.ReactNode;
  disableHours?: boolean;
  shouldDisableDate?: (date: Date) => boolean;
  anchorEl: HTMLElement | null;
  minDate?: Date;
  maxDate?: Date;
  onCancel?: () => void;
  range?: boolean;
  from: Date | undefined;
  to?: Date;
  open: boolean;
  closePicker: () => void;
  setTime: (from: Date, to: Date | null) => void;
  startLive?: (liveIntervalIndex: number, cb?: unknown) => void;
  showTabs?: boolean;
}

interface State {
  internalStartDate: Date | null;
  internalEndDate: Date | -1 | null;
  disableOkButton: boolean;
  timePickerError: boolean | null;
  customTabValue: number;
  recentBatches: number | null;
  shifts: number | null;
}

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

type SuperProps = Properties & WithTranslation & WithUtils & WithLine & WithStyles<typeof styles> & WithWidth;

class CustomDateTimePicker extends React.Component<SuperProps> {
  readonly state: State = {
    internalStartDate: null,
    internalEndDate: null,
    disableOkButton: false,
    timePickerError: null,
    customTabValue: 0,
    recentBatches: 0,
    shifts: 0,
  };

  private updateShiftsInState = (value: number) => {
    this.setState({ shifts: value });
  };

  formatDate = (utils: WithUtils['utils'], date: Date) => {
    return date
      ? isBrowserLocale24h(this.props.i18n.language)
        ? utils.formatByString(date, 'dd/MM - yyyy, HH:mm')
        : utils.formatByString(date, 'MM/dd/yyyy, hh:mm a')
      : '';
  };

  startDate = (from?: Date): Date => {
    if (this.state.internalStartDate) {
      return this.state.internalStartDate;
    } else if (from) {
      return from;
    }
    // NOTE: Should not get to here, but we fallback to current date.
    return new Date();
  };

  endDate = (to?: Date | null): Date | null => {
    if (this.state.internalEndDate) {
      if (this.state.internalEndDate === -1) {
        return null;
      } else {
        return this.state.internalEndDate;
      }
    } else if (to) {
      return to;
    }
    return null;
  };

  updateTime = (start: Date, end?: Date) => {
    this.setState({ internalStartDate: start, internalEndDate: end });

    if (end && start > end) {
      this.setState({ timePickerError: true, disableOkButton: true });
    } else {
      this.setState({ timePickerError: null, disableOkButton: false });
    }
  };

  getCalendar = (range: boolean) => {
    const {
      disableHours,
      disablePast,
      disableFuture,
      leftArrowIcon,
      rightArrowIcon,
      utils,
      t,
      shouldDisableDate,
      minDate,
      maxDate,
      from,
      to,
    } = this.props;

    if (range) {
      return (
        <div>
          <DoubleCalendar
            startDate={this.startDate(from)}
            endDate={this.endDate(to) || new Date()}
            onChange={this.updateTime}
            disablePast={disablePast || false}
            disableFuture={disableFuture || false}
            minDate={utils.date(minDate || '1900-01-01') ?? undefined}
            maxDate={utils.date(maxDate || '2100-01-01') ?? undefined}
            leftArrowIcon={leftArrowIcon || <ArrowBack />}
            rightArrowIcon={rightArrowIcon || <ArrowForward />}
            shouldDisableDate={shouldDisableDate}
          />
          {this.state.timePickerError !== null && (
            <div>
              <WideSnackbar
                className={this.props.classes.doubleCalendarError}
                message={t(['shared:negativeTimeNotAllowed'], {
                  defaultValue: 'Your end time cannot be before your start time',
                })}
              />{' '}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <SingleCalendar
          disableHours={disableHours}
          date={this.startDate(from)}
          onChange={this.updateTime}
          disablePast={disablePast || false}
          disableFuture={disableFuture || false}
          minDate={utils.date(minDate || '1900-01-01') ?? undefined}
          maxDate={utils.date(maxDate || '2100-01-01') ?? undefined}
          leftArrowIcon={leftArrowIcon || <ArrowBack />}
          rightArrowIcon={rightArrowIcon || <ArrowForward />}
          shouldDisableDate={shouldDisableDate}
        />
      );
    }
  };

  getSelectedText = (range: boolean) => {
    const { classes, utils, t, from, to } = this.props;

    if (range && from && to) {
      return (
        <div className={classes.actionTypography}>
          <Typography color="primary">{t(['shared:selected'], { defaultValue: 'Selected' }).toUpperCase()}:</Typography>
          <Typography style={{ marginLeft: '5px' }}>{this.formatDate(utils, this.startDate(from)!)}</Typography>
          <Typography color="primary" style={{ marginLeft: '5px' }}>
            {t(['shared:dateTo'], { defaultValue: 'to' })}
          </Typography>
          <Typography style={{ marginLeft: '5px' }}>{this.formatDate(utils, this.endDate(to)!)}</Typography>
        </div>
      );
    }
  };

  handleChangeCustomTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ customTabValue: newValue });
  };

  TabPanel = (props: TabPanelProps) => {
    const { theme, classes } = this.props;
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`calender-tabpanel-${index}`}
        aria-labelledby={`calender-tab-${index}`}
        className={classes.content}
      >
        {value === index && (
          <Box p={3} style={{ paddingTop: theme.spacing() }}>
            {children}
          </Box>
        )}
      </div>
    );
  };

  private selectedBatchTimes = (selectedTimes: Schema.RecentBatchesFromLineQuery['line']['batches']['items'][0]) => {
    const end = selectedTimes.actualStop ? new Date(selectedTimes.actualStop) : new Date();
    const start = selectedTimes.actualStart ? new Date(selectedTimes.actualStart) : new Date(end.getTime() - (1).hours);

    this.updateTime(start, end);
  };

  private getRecentBatches = () => {
    const { classes, lineId } = this.props;

    if (!lineId) {
      return (
        <div className={classes.loading}>
          <LoadingOrError loading={false} />
        </div>
      );
    }

    const batchVariables: Schema.LineBatchesQueryVariables = {
      lineId,
      filter: {
        state: Schema.BatchState.COMPLETED,
        exclusiveFromCheck: false,
      },
    };

    return (
      <Apollo.Query<Schema.LineBatchesQuery, Schema.LineBatchesQueryVariables>
        ssr={false}
        partialRefetch
        query={recentBatchesFromLine}
        errorPolicy="all" // Necessary for returning partial data.
        fetchPolicy="cache-and-network"
        variables={batchVariables}
      >
        {({ data: batchResponse, loading }) => {
          const { t } = this.props;
          if (loading && !batchResponse) {
            return (
              <div className={classes.loading}>
                <LoadingOrError loading={true} />
              </div>
            );
          }

          const fieldName: keyof Schema.LineBatchesQuery | keyof Schema.BatchesPeripheralsFromLineSimpleQuery = 'line';
          const batchLine = batchResponse && batchResponse[fieldName as 'line'];

          const items = batchLine?.batches?.items ?? [];
          if (items.length > 0) {
            if (items.length !== this.state.recentBatches) {
              this.setState({ recentBatches: items.length });
            }
            return (
              <RecentBatchesSelector
                utils={this.props.utils}
                batchItems={items}
                batchSelected={this.selectedBatchTimes}
                t={t}
              />
            );
          } else {
            return (
              <Typography align="center" color="secondary">
                {' '}
                {t(['shared:NotRunningBatches'], {
                  defaultValue: 'You have not run any batches yet. Go to the Batches tab to start your first batch.',
                })}{' '}
              </Typography>
            );
          }
        }}
      </Apollo.Query>
    );
  };

  private renderTabs = () => {
    const { t, lineId } = this.props;
    const tabs = [
      <Tab
        key={0}
        label={t(['shared:calendar'], { defaultValue: 'Calendar' })}
        aria-controls="calendar-tabpanel-0"
        id="calendar-tab-0"
      />,
      <Tab
        key={1}
        label={t(['shared:relative'], { defaultValue: 'Relative' })}
        aria-controls="calendar-tabpanel-1"
        id="calendar-tab-1"
      />,
    ];

    if (lineId) {
      tabs.push(
        <Tab
          key={2}
          label={t(['shared:recent-batches'], { defaultValue: 'Recent batches' })}
          aria-controls="calendar-tabpanel-2"
          id="calendar-tab-2"
        />,
        <Tab
          key={3}
          label={t(['shared:recent-shifts'], { defaultValue: 'Recent shifts' })}
          aria-controls="calendar-tabpanel-3"
          id="calendar-tab-3"
        />,
      );
    }

    return (
      <Tabs
        variant="scrollable"
        value={this.state.customTabValue}
        onChange={this.handleChangeCustomTab}
        aria-label="calender tabs"
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </Tabs>
    );
  };

  getOuter(children: React.ReactElement) {
    const { anchorEl, open, classes } = this.props;
    if (this.props.width === 'xs') {
      return (
        <Modal open={open} onClose={this.onClose} disableScrollLock={false}>
          <div className={classes.modalContainer}>{children}</div>
        </Modal>
      );
    } else {
      return (
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={this.onClose}
        >
          {children}
        </Popover>
      );
    }
  }

  render() {
    const { lineId, classes, t, showTabs, range = false } = this.props;

    return this.getOuter(
      <Card elevation={1} className={classes.card}>
        <>
          {showTabs && this.renderTabs()}

          <this.TabPanel value={this.state.customTabValue} index={0}>
            <CardContent>{this.getCalendar(range)}</CardContent>
          </this.TabPanel>

          <this.TabPanel value={this.state.customTabValue} index={1}>
            <RelativeTimes t={t} updateTime={this.updateTime} />
          </this.TabPanel>

          {lineId && (
            <>
              <this.TabPanel value={this.state.customTabValue} index={2}>
                {this.getRecentBatches()}
              </this.TabPanel>

              <this.TabPanel value={this.state.customTabValue} index={3}>
                <RecentShifts
                  lineId={lineId}
                  updateTime={this.updateTime}
                  utils={this.props.utils}
                  updateShifts={this.updateShiftsInState}
                  t={t}
                />
              </this.TabPanel>
            </>
          )}
        </>

        <Divider />
        <CardActions className={classes.actionContainer}>
          {this.getSelectedText(range)}
          <div className={classes.actionButtonGroup}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={this.onClose}
              className={classes.actionButton}
            >
              {t(['shared:cancel'], { defaultValue: 'Cancel' })}
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              size="small"
              variant="contained"
              color="primary"
              onClick={this.onOk}
              className={classes.actionButton}
              disabled={this.state.disableOkButton}
            >
              {t(['shared:ok'], { defaultValue: 'Ok' })}
            </Button>
          </div>
        </CardActions>
      </Card>,
    );
  }

  private onClose = () => {
    this.props.onCancel?.();

    this.props.closePicker();
  };

  private onOk = () => {
    const { from, to, setTime } = this.props;

    setTime(this.startDate(from), this.endDate(to));
  };
}

const enhance = compose<SuperProps, Properties>(
  withUtils,
  withLine,
  withTranslation(['shared']),
  withStyles(styles, { withTheme: true }),
  withWidth(),
);
export default enhance(CustomDateTimePicker);
