import { SnackbarContent } from '@mui/material';
import { SnackbarContentProps } from '@mui/material/SnackbarContent';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { compose } from 'recompose';

import { WithStyles, withStyles } from '@/hocs/with-styles';

export interface SearchValue {
  label: string;
  value: {
    type: 'string' | 'group' | 'type';
    value: string;
  };
}
export interface Props {
  backgroundColor?: string;
}

const styles = (_theme: Theme) => ({
  snackbar: (props: Props) => ({
    // backgroundColor: props.backgroundColor ? props.backgroundColor : amber[700],
    maxWidth: '100%',
    marginBottom: '1em',
  }),
});

class WideSnackBar extends React.Component<SnackbarContentProps & Props & WithStyles<typeof styles>> {
  public render() {
    const { classes, ...rest } = this.props;

    return <SnackbarContent className={classes.snackbar} {...rest} />;
  }
}
const enhance = compose<unknown, SnackbarContentProps & Props>(withStyles(styles, { withTheme: true }));
export default enhance(WideSnackBar as React.ComponentType<unknown>);
