import * as Apollo from '@apollo/client/react/components';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import { TFunction } from 'i18next';
import React from 'react';
import { compose } from 'recompose';

import LoadingOrError from '@/components/loading/loading-or-error';
import { shiftTimes } from '@/graphql/queries';
import { getDaySortNumber, getDayStringOfEnum } from '@/helpers/schedule';
import { convertGenericShiftTimeToDatePair, getYearWeek } from '@/helpers/schedule';
import { WithUtils } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  fillHeight: {
    height: '100%',
  },
  loading: { width: '100%', height: '100%', display: 'flex' },
  boxHeight: {
    height: '400px',
    overflowY: 'scroll',
  },
});

interface State {
  shiftIndex: string | null;
  shifts: Pick<Schema.Shift, 'id' | 'name' | 'timeRange'>[];
  configuration: Pick<Schema.ScheduleConfiguration, 'timezone' | 'startDayOfWeek'> | null;
}

interface Properties {
  lineId: string;
  updateTime: (startDate: Date, endDate: Date) => void;
  updateShifts: (shifts: number) => void;
  utils: WithUtils['utils'];
  t: TFunction;
}

const zeroPadding = (s: string | number): string => {
  if ((typeof s === 'string' && s.length < 2) || (typeof s === 'number' && s < 10)) {
    return `0${s}`;
  }
  return `${s}`;
};

class RecentShifts extends React.Component<Properties & WithStyles<typeof styles>, State> {
  readonly state: State = {
    shiftIndex: null,
    shifts: [],
    configuration: null,
  };

  private getFromAndTo = (start: Date, end: Date) => {
    const { utils, t } = this.props;
    return t(['shared:dateRangeTo'], {
      defaultValue: '{{fromDate}} to {{toDate}}',
      fromDate: utils.formatByString(start, 'dd/MM - yyyy, HH:mm'),
      toDate: utils.formatByString(end, 'dd/MM - yyyy, HH:mm'),
    });
  };

  private getTimeRangeDates = (timeRange: Schema.ShiftTimeRange) => {
    // Convert the generic shift into a current shift.
    const currentDate = new Date();
    const startDay = this.state.configuration?.startDayOfWeek ?? Schema.DayOfWeek.MONDAY;
    const { from: startDate, to: endDate } = convertGenericShiftTimeToDatePair(timeRange, currentDate, startDay);

    // If start date is prior to now, than we need to move a week back and find the start date again.
    if (currentDate < startDate) {
      const lastWeek = new Date(currentDate.getTime() - (7).days);

      const { from: startDate, to: endDate } = convertGenericShiftTimeToDatePair(timeRange, lastWeek, startDay);

      return {
        startDate,
        endDate,
      };
    } else {
      return { startDate, endDate };
    }
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: shiftId } = event.target;

    this.setState({ shiftIndex: shiftId });

    const selectedShift = this.state.shifts.find((item) => item.id === shiftId);
    if (!selectedShift) {
      return;
    }

    const { startDate, endDate } = this.getTimeRangeDates(selectedShift.timeRange);
    this.props.updateTime(startDate, endDate);
  };

  private handleOnCompleted = (data: Schema.ShiftTimesQuery) => {
    const shifts = [...(data.line.schedule?.shifts ?? [])];
    const configuration = data.line.schedule?.configuration ?? null;

    if (shifts.length) {
      shifts.sort((a, b) => {
        const aDay = getDaySortNumber(a.timeRange.from.day, configuration?.startDayOfWeek);
        const bDay = getDaySortNumber(b.timeRange.from.day, configuration?.startDayOfWeek);
        const aHour = a.timeRange.from.hour;
        const bHour = b.timeRange.from.hour;
        if (aDay < bDay) {
          return -1;
        } else if (
          aDay === bDay &&
          (aHour < bHour || (aHour === bHour && a.timeRange.from.minute < b.timeRange.from.minute))
        ) {
          return -1;
        }
        return 1;
      });

      if (!this.state.shifts.length) {
        this.setState({ shifts });
        this.props.updateShifts(shifts.length);
      }

      if (
        this.state.configuration === null ||
        this.state?.configuration?.startDayOfWeek !== configuration?.startDayOfWeek
      ) {
        this.setState({ configuration });
      }
    }
  };

  public render() {
    const { lineId, classes, theme } = this.props;
    const currentDate = new Date();
    const nowFilter = getYearWeek(currentDate);

    const scheduleVariables: Schema.LineScheduleQueryVariables = {
      lineId,
      validIn: nowFilter,
    };

    return (
      <Apollo.Query<Schema.ShiftTimesQuery, Schema.ShiftTimesQueryVariables>
        query={shiftTimes}
        ssr={false}
        partialRefetch
        fetchPolicy="cache-and-network"
        onCompleted={this.handleOnCompleted}
        errorPolicy="all" // Necessary for returning partial data.
        variables={scheduleVariables}
      >
        {({ data, loading }) => {
          if (loading && !data) {
            return (
              <div className={classes.loading}>
                <LoadingOrError loading={true} />
              </div>
            );
          }
          const { t } = this.props;

          const shifts = (() => {
            const { shifts } = this.state;

            return shifts || [];
          })();

          if (shifts.length) {
            return (
              <div className={classes.boxHeight}>
                <FormControl variant="filled" component="fieldset">
                  <RadioGroup
                    aria-label="recent-batches"
                    name="recent-batches"
                    value={this.state.shiftIndex}
                    onChange={this.handleChange}
                  >
                    {shifts
                      .map((item) => {
                        const { startDate, endDate } = this.getTimeRangeDates(item.timeRange);
                        return {
                          item,
                          startDate,
                          endDate,
                        };
                      })
                      .sort(({ startDate: leftStartDate }, { startDate: rightStartDate }) => {
                        return rightStartDate.getTime() - leftStartDate.getTime();
                      })
                      .map(({ startDate, endDate, item }) => {
                        return (
                          <FormControlLabel
                            key={item.id}
                            value={item.id}
                            control={<Radio color="primary" style={{ marginBottom: theme.spacing(1.5) }} />}
                            label={
                              <>
                                <Typography variant="body2">{`${getDayStringOfEnum(t, item.timeRange.from.day)}: ${
                                  item.name
                                }`}</Typography>
                                <Typography color="textSecondary" variant="caption">
                                  {`${getDayStringOfEnum(t, item.timeRange.from.day)} ${zeroPadding(
                                    item.timeRange.from.hour,
                                  )}:${zeroPadding(item.timeRange.from.minute)} - ${getDayStringOfEnum(
                                    t,
                                    item.timeRange.to.day,
                                  )} ${zeroPadding(item.timeRange.to.hour)}:${zeroPadding(item.timeRange.to.minute)}`}
                                </Typography>
                              </>
                            }
                            labelPlacement="end"
                            title={this.getFromAndTo(startDate, endDate)}
                          />
                        );
                      })}
                  </RadioGroup>
                </FormControl>
              </div>
            );
          } else {
            return (
              <Typography color="secondary" align="center">
                {t(['shared:noShifts'], {
                  defaultValue: 'No shifts. Go to your schedule to set up your shift times.',
                })}
              </Typography>
            );
          }
        }}
      </Apollo.Query>
    );
  }
}

const enhance = compose<unknown, Properties>(withStyles(styles, { withTheme: true }));
export default enhance(RecentShifts as React.ComponentType<unknown>);
