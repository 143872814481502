import { TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { CalendarHeader, Day, DayWrapper } from '@/components/pickers/date-time-range-picker';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import { WithUtils, withUtils } from '@/hocs/with-utils';

const styles = (theme: Theme) => ({
  calendar: {
    height: 36 * 6,
    marginTop: theme.spacing(1.5),
  },
  week: {
    display: 'flex',
    justifyContent: 'center',
  },
  calendarWrapper: {
    display: 'flex',
  },
  hour: {
    maxWidth: '7em',
  },
  minute: {
    maxWidth: '7em',
  },
  inputField: {
    fontSize: '17px',
    textAlign: 'center',
  },
  timeContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  vr: {
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    top: '0',
    bottom: '0',
    left: '150px',
  },
});

interface Properties {
  startDate: Date;
  endDate: Date;
  minDate?: string | number | Date;
  maxDate?: string | number | Date;
  onChange: (from: Date, to?: Date) => void;
  disablePast: boolean;
  disableFuture: boolean;
  leftArrowIcon: React.ReactNode;
  rightArrowIcon: React.ReactNode;
  shouldDisableDate?: (date: Date) => boolean;
}

interface State {
  currentMonth: Date;
  currentState: string;
  hoveredDay: Date | null;
}

/* eslint-disable no-unused-expressions */
class DoubleCalendar extends React.Component<
  Properties & WithUtils & WithStyles<typeof styles> & WithTranslation,
  State
> {
  static defaultProps: Omit<Properties, 'startDate' | 'endDate' | 'onChange'> = {
    minDate: '1900-01-01',
    maxDate: '2100-01-01',
    disablePast: false,
    disableFuture: false,
    leftArrowIcon: undefined,
    rightArrowIcon: undefined,
    shouldDisableDate: () => false,
  };

  readonly state: State = {
    currentMonth: this.props.utils.startOfMonth(this.props.utils.date() ?? new Date()),
    currentState: 'start',
    hoveredDay: null,
  };

  componentDidMount() {
    this.setState({ currentState: 'start' });
  }

  onDayHover = (enter: boolean, day: Date) => {
    if (enter) {
      this.setState({ hoveredDay: day });
    } else {
      this.setState({ hoveredDay: null });
    }
  };

  onDateSelect = (day: Date) => {
    const { startDate, endDate, utils } = this.props;
    if (startDate && endDate) {
      if (utils.isAfterDay(day, utils.startOfDay(endDate)) && this.state.currentState !== 'end') {
        // Set startdate, clear endDate and set state to 'end'
        const withHours = utils.setHours(day, utils.getHours(startDate));
        const withMinutes = utils.setMinutes(withHours, utils.getMinutes(startDate));
        this.setState({ currentState: 'end' });
        this.props.onChange(withMinutes, withMinutes);
      } else if (this.state.currentState === 'end' && !utils.isBeforeDay(day, utils.startOfDay(startDate))) {
        // Set enddate and set state to 'start'
        const withHours = utils.setHours(day, utils.getHours(endDate));
        const withMinutes = utils.setMinutes(withHours, utils.getMinutes(endDate));
        this.setState({ currentState: 'start' });
        this.props.onChange(startDate, withMinutes);
      } else {
        // Set startDate, keep endDate and set state to 'end'
        const withHours = utils.setHours(day, utils.getHours(startDate));
        const withMinutes = utils.setMinutes(withHours, utils.getMinutes(startDate));
        this.setState({ currentState: 'end' });
        this.props.onChange(withMinutes, endDate);
      }
    } else if (startDate) {
      // Set end Date
      if (utils.isBeforeDay(day, utils.startOfDay(startDate))) {
        this.props.onChange(day, undefined);
      } else {
        this.setState({ currentState: 'start' });
        this.props.onChange(startDate, day);
      }
    } else {
      // Set startDate
      this.setState({ currentState: 'end' });
      this.props.onChange(day, endDate);
    }
  };

  handleNextMonth = () => {
    this.setState({
      currentMonth: this.props.utils.getNextMonth(this.state.currentMonth),
    });
  };

  handlePreviousMonth = () => {
    this.setState({
      currentMonth: this.props.utils.getPreviousMonth(this.state.currentMonth),
    });
  };

  validateMinMaxDate = (day: Date) => {
    const { minDate, maxDate, utils } = this.props;

    const parsedMinDate = minDate instanceof Date ? minDate : new Date(minDate ?? '');
    const parsedMaxDate = maxDate instanceof Date ? maxDate : new Date(maxDate ?? '');

    return (
      (parsedMinDate && utils.isBeforeDay(day, parsedMinDate)) ||
      (parsedMaxDate && utils.isAfterDay(day, parsedMaxDate))
    );
  };

  shouldDisablePrevMonth = () => {
    const { utils, disablePast, minDate } = this.props;
    const now = new Date();
    return !utils.isBefore(
      utils.startOfMonth(disablePast && utils.isAfter(now, new Date(minDate ?? '')) ? now : new Date(minDate ?? '')),
      utils.getPreviousMonth(this.state.currentMonth),
    );
  };

  shouldDisableNextMonth = () => {
    const { utils, disableFuture, maxDate } = this.props;
    const now = new Date();
    return !utils.isAfter(
      utils.startOfMonth(disableFuture && utils.isBefore(now, new Date(maxDate ?? '')) ? now : new Date(maxDate ?? '')),
      this.state.currentMonth,
    );
  };

  shouldDisableDate = (day: Date) => {
    const { disablePast, disableFuture, shouldDisableDate, utils } = this.props;

    return (
      (disableFuture && utils.isAfterDay(day, new Date())) ||
      (disablePast && utils.isBeforeDay(day, new Date())) ||
      this.validateMinMaxDate(day) ||
      shouldDisableDate?.(day)
    );
  };

  moveToDay = (day: Date) => {
    if (day && !this.shouldDisableDate(day)) {
      this.props.onChange(day);
    }
  };

  renderWeeks = (prev: boolean) => {
    const { utils } = this.props;

    let { currentMonth } = this.state;
    if (prev) {
      currentMonth = utils.getPreviousMonth(currentMonth);
    }
    const weeks = utils.getWeekArray(currentMonth);

    return weeks.map((week) => (
      <div key={`week-${week[0].toString()}`} className={this.props.classes.week}>
        {this.renderDays(week, currentMonth)}
      </div>
    ));
  };

  renderDays = (week: Date[], currentMonth: Date) => {
    let { startDate, endDate } = this.props;
    const { utils } = this.props;

    const currentMonthNumber = utils.getMonth(currentMonth);
    const now = new Date();

    if (endDate) {
      endDate = utils.startOfDay(endDate);
    }

    if (startDate) {
      startDate = utils.startOfDay(startDate);
    }

    return week.map((day) => {
      const disabled = this.shouldDisableDate(day);
      const dayInCurrentMonth = utils.getMonth(day) === currentMonthNumber;
      let selected = false;
      let betweenSelected = false;
      if (endDate) {
        selected = utils.isSameDay(endDate, day);
      }

      if (startDate && !selected) {
        selected = utils.isSameDay(startDate, day);
      }

      if (startDate && endDate) {
        betweenSelected = utils.isBeforeDay(day, endDate) && utils.isAfterDay(day, startDate);
      }

      if (this.state.hoveredDay && !endDate) {
        betweenSelected =
          utils.isAfterDay(day, utils.startOfDay(startDate)) &&
          utils.isBeforeDay(day, utils.startOfDay(this.state.hoveredDay));
      }

      return (
        <DayWrapper
          key={day.toString()}
          value={day}
          dayInCurrentMonth={dayInCurrentMonth}
          disabled={disabled}
          onSelect={this.onDateSelect}
          onMouseEnter={this.handleOnMouse(disabled, dayInCurrentMonth, day, true)}
          onMouseLeave={this.handleOnMouse(disabled, dayInCurrentMonth, day, false)}
        >
          <Day
            current={utils.isSameDay(day, now)}
            hidden={!dayInCurrentMonth}
            disabled={disabled}
            selected={selected}
            betweenSelected={betweenSelected}
          >
            {utils.formatByString(day, 'd')}
          </Day>
        </DayWrapper>
      );
    });
  };

  render() {
    const { currentMonth } = this.state;
    const { classes, utils, startDate, endDate, t } = this.props;

    return (
      <div className={classes.calendarWrapper}>
        <div>
          <CalendarHeader
            currentMonth={utils.getPreviousMonth(currentMonth)}
            onNextMonth={this.handleNextMonth}
            onPreviousMonth={this.handlePreviousMonth}
            leftArrowIcon={this.props.leftArrowIcon}
            disablePrevMonth={this.shouldDisablePrevMonth()}
            disableNextMonth={true}
          />

          <div className={classes.calendar}>{this.renderWeeks(true)}</div>

          <div className={classes.timeContainer}>
            <TextField
              variant="filled"
              id="hour"
              value={utils.getHours(startDate)}
              label={t(['shared:hour'], { defaultValue: 'hour' }).titleCase}
              type="number"
              className={classes.hour}
              inputProps={{ className: classes.inputField }}
              onChange={this.onChangeTime('start', 'hour')}
            />
            <TextField
              variant="filled"
              id="min"
              value={utils.getMinutes(startDate)}
              label={t(['shared:abbrMinutes'], { defaultValue: 'min' }).titleCase}
              type="number"
              className={classes.minute}
              inputProps={{ className: classes.inputField }}
              onChange={this.onChangeTime('start', 'min')}
            />
          </div>
        </div>

        <div className={classes.vr}>&nbsp;</div>

        <div>
          <CalendarHeader
            currentMonth={currentMonth}
            onNextMonth={this.handleNextMonth}
            onPreviousMonth={this.handlePreviousMonth}
            rightArrowIcon={this.props.rightArrowIcon}
            disablePrevMonth={true}
            disableNextMonth={this.shouldDisableNextMonth()}
          />

          <div className={classes.calendar}>{this.renderWeeks(false)}</div>

          <div className={classes.timeContainer}>
            <TextField
              variant="filled"
              id="hour"
              label={t(['shared:hour'], { defaultValue: 'hour' }).titleCase}
              value={utils.getHours(endDate)}
              type="number"
              className={classes.hour}
              inputProps={{ className: classes.inputField }}
              onChange={this.onChangeTime('end', 'hour')}
            />
            <TextField
              variant="filled"
              id="min"
              label={t(['shared:abbrMinutes'], { defaultValue: 'min' }).titleCase}
              value={utils.getMinutes(endDate)}
              type="number"
              className={classes.minute}
              inputProps={{ className: classes.inputField }}
              onChange={this.onChangeTime('end', 'min')}
            />
          </div>
        </div>
      </div>
    );
  }

  private handleOnMouse = (disabled: unknown, dayInCurrentMonth: boolean, day: Date, enter: boolean) => () => {
    if (!disabled && dayInCurrentMonth) {
      this.onDayHover(enter, day);
    }
  };

  private onChangeTime =
    (startEnd: string, hourMinutes: string) =>
    (e: {
      target: {
        value: string;
      };
    }) => {
      let { startDate, endDate } = this.props;
      const { utils } = this.props;

      let val = Number(e.target.value);
      if (hourMinutes === 'hour') {
        // Limit hours to 0-24, with rollover
        if (val > 23) {
          val = 0;
        } else if (val < 0) {
          val = 23;
        }

        if (startEnd === 'start') {
          startDate = utils.setHours(startDate, val);
        } else {
          endDate = utils.setHours(endDate, val);
        }
      } else {
        // Limit minutes to 0-59, with rollover
        if (val > 59) {
          val = 0;
        } else if (val < 0) {
          val = 59;
        }
        if (startEnd === 'start') {
          startDate = utils.setMinutes(startDate, val);
        } else {
          endDate = utils.setMinutes(endDate, val);
        }
      }

      this.props.onChange(startDate, endDate);
    };
}
const enhance = compose<unknown, Properties>(
  withUtils,
  withTranslation(['shared']),
  withStyles(styles, { name: 'MUITPickersCalendar' }),
);
export default enhance(DoubleCalendar as React.ComponentType<unknown>);
