import { Icon, IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { compose } from 'recompose';

import { WithUtils, withUtils } from '@/hocs';
import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  switchHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(),
  },
  daysHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dayLabel: {
    width: 36,
    margin: '0 2px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

interface Properties {
  currentMonth: Date;
  onNextMonth: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onPreviousMonth: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  leftArrowIcon?: React.ReactNode;
  rightArrowIcon?: React.ReactNode;
  disablePrevMonth: boolean;
  disableNextMonth: boolean;
}

class CalendarHeader extends React.PureComponent<Properties & WithUtils & WithStyles<typeof styles>, {}> {
  static defaultProps = {
    leftArrowIcon: 'keyboard_arrow_left',
    rightArrowIcon: 'keyboard_arrow_right',
    disablePrevMonth: false,
    disableNextMonth: false,
  };

  render() {
    const {
      classes,
      theme,
      currentMonth,
      onNextMonth,
      onPreviousMonth,
      leftArrowIcon,
      rightArrowIcon,
      disablePrevMonth,
      disableNextMonth,
      utils,
    } = this.props;

    const rtl = theme.direction === 'rtl';

    return (
      <div>
        <div className={classes.switchHeader}>
          <IconButton disabled={disablePrevMonth} onClick={onPreviousMonth} size="large">
            <Icon>{rtl ? rightArrowIcon : leftArrowIcon}</Icon>
          </IconButton>

          <Typography variant="body2">{utils.formatByString(currentMonth, 'LLLL yyyy')}</Typography>

          <IconButton disabled={disableNextMonth} onClick={onNextMonth} size="large">
            <Icon>{rtl ? leftArrowIcon : rightArrowIcon}</Icon>
          </IconButton>
        </div>

        <div className={classes.daysHeader}>
          {utils.getWeekdays().map((day, index) => (
            <Typography key={index} variant="caption" className={classes.dayLabel}>
              {day}
            </Typography>
          ))}
        </div>
      </div>
    );
  }
}
const enhance = compose<unknown, Properties>(
  withUtils,
  withStyles(styles, { withTheme: true, name: 'MuiPickersCalendarHeader' }),
);
export default enhance(CalendarHeader as React.ComponentType<unknown>);
