import { IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';

import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium as any,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  current: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  selectedColor: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  betweenSelected: {
    color: theme.palette.common.black,
    backgroundColor: `${theme.palette.primary.main}30`,
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  disabled: {
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
  },
});

interface Properties {
  children: React.ReactNode;
  current: boolean;
  disabled: boolean;
  hidden: boolean;
  selected: boolean;
  betweenSelected: boolean;
  className?: string;
}

const Day: React.FC<Properties & WithStyles<typeof styles>> = (props) => {
  const { children, classes, disabled, hidden, current, selected, betweenSelected, className, ...other } = props;

  const extendedClassName = clsx(classes.day, className, {
    [classes.hidden]: hidden,
    [classes.current]: current,
    [classes.selectedColor]: selected,
    [classes.betweenSelected]: betweenSelected && !selected && !disabled && !hidden,
    [classes.disabled]: disabled,
  });

  return (
    <IconButton className={extendedClassName} tabIndex={hidden || disabled ? -1 : 0} {...other} size="large">
      <span> {children} </span>
    </IconButton>
  );
};

export default withStyles(styles, { name: 'MuiPickersDay' })(Day);
